import React, {useCallback, useEffect, useState} from 'react';

import {
  useTheme,
  Typography,
  Card,
  CardHeader,
  Button,
  CardContent,
  CircularProgress,
} from '@mui/material';
import {Box, Container} from '@mui/system';
import {useConditionalEffect, useToggle} from '@react-hookz/web';
import {useRecoilState, useRecoilValue} from 'recoil';

import atoms from '../atoms';
import AppBar from '../components/AppBar';
import ContinueDialog from '../components/ContinueDialog';
import NoSolarScoreCard from '../components/NoSolarScoreCard';
import SearchWidget from '../components/SearchWidget';
import SolarScoreCard from '../components/SolarScoreCard';
import SolarScoreToolTip from '../components/SolarScoreToolTip';
import SolarTipCard from '../components/SolarTipCard';
import LookSeeServices from '../services/LookSeeServices';
import {sendComponentAnalytics} from '../utils/Analytics';

export default function LandingPage() {
  const user = useRecoilValue(atoms.user);
  const dailyTip = useRecoilValue(atoms.dailyTip);
  const [solarScore, setSolarScore] = useState();
  const [onCancel, setOnCancel] = useToggle(false);
  const [solarScoreToolTipOpen, setSolarScoreToolTipOpen] = useToggle();
  const [openDialogQuote, setOpenDialogQuote] = useState(false);
  const [openDialogTip, setOpenDialogTip] = useState(false);
  const [showPrimaryAddress, setShowPrimaryAddress] = useToggle(true);
  const [userAddress, setUserAddress] = useRecoilState(atoms.userAddress);
  const [primaryAddressSolarScoreResult, setPrimaryAddressSolarScoreResult] =
    useRecoilState(atoms.primaryAddressSolarScoreResult);

  const handleOpenDialogQuote = () => {
    setOpenDialogQuote(!openDialogQuote);
  };

  const handleOpenDialogTip = () => {
    setOpenDialogTip(!openDialogTip);
  };

  const theme = useTheme();

  const solarScoreCardTitle = (
    <Box display="flex" columnGap="1%" alignItems="center">
      <Box>
        <Typography variant="Header3">Your Solar Score</Typography>
      </Box>
      <Box>
        <Button
          data-id="link_content"
          dataIntent="informational"
          dataScope="tool tip"
          data-text="looksee | home | Your Solar Score tool tip click"
          onClick={setSolarScoreToolTipOpen}>
          <img src={theme.icons.InfoIcon} alt="solar-score info tooltip" />
        </Button>
      </Box>
      <SolarScoreToolTip
        open={solarScoreToolTipOpen}
        onClose={setSolarScoreToolTipOpen}
      />
    </Box>
  );

  const solarScoreCardSubTitle = (
    <Typography variant="Subtitle1" color={theme.palette.text.darkNavyBlue}>
      Understand your property&apos;s solar potential
    </Typography>
  );

  const onSearchResultSelect = useCallback(
    async (searchResult, isPrimaryAddress) => {
      const propertyKey = searchResult?.property_key;
      const subplaceId = searchResult?.subplace_id;
      const isValidSearchResult =
        searchResult?.category === 'StreetAddress' &&
        searchResult?.sub_category !== 'SchemeUnit' &&
        !searchResult?.property_has_sectional_title_units;

      sendComponentAnalytics({searchTerm: 'solar score'}, 'search_form');

      if (isValidSearchResult) {
        try {
          const result = await LookSeeServices.getSolarScore(
            propertyKey,
            subplaceId,
          );
          setSolarScore(result.score);
          if (isPrimaryAddress) {
            setShowPrimaryAddress(true);
            setPrimaryAddressSolarScoreResult(result.score);
          } else {
            setShowPrimaryAddress(false);
          }
        } catch (error) {
          sendComponentAnalytics(
            {
              'solar score not available right now':
                'solar score not available right now',
            },
            'platformMessage',
          );
          setSolarScore(null);
          setShowPrimaryAddress(true);
        }
      } else {
        setSolarScore(null);
      }
    },
    [],
  );

  useEffect(() => {
    if (user?.userAddress) {
      setShowPrimaryAddress(true);
      setUserAddress(user?.userAddress);
    }
    if (primaryAddressSolarScoreResult) {
      setSolarScore(primaryAddressSolarScoreResult);
    } else {
      setSolarScore(null);
    }
  }, [
    user,
    primaryAddressSolarScoreResult,
    setShowPrimaryAddress,
    setUserAddress,
  ]);

  useConditionalEffect(
    () => {
      setSolarScore(null);
      setShowPrimaryAddress(true);
      if (primaryAddressSolarScoreResult) {
        setSolarScore(primaryAddressSolarScoreResult);
      }
    },
    [onCancel],
    [onCancel || !onCancel],
  );

  return (
    <Box pb="10%">
      <AppBar />
      <Container>
        <Box mt="7%">
          <Typography
            variant="Header1"
            color={theme.palette.text.primary}
            style={{...theme.styles.font.bold}}>
            Hello {user?.first_name ?? ''}
          </Typography>
        </Box>
        <Box mt="5%">
          <Typography
            variant="Subtitle1"
            color={theme.palette.text.darkNavyBlue}>
            Your daily energy tip
          </Typography>
          <Box mt="8px">
            {dailyTip && (
              <SolarTipCard
                title={dailyTip?.title}
                tip={dailyTip?.tip}
                handleClick={handleOpenDialogTip}
              />
            )}
          </Box>
        </Box>
        <Box mt="5%">
          <Card
            sx={{
              borderRadius: theme.styles.card.radius.main,
              boxShadow: theme.palette.card.boxShadow.main,
              minHeight: '300px',
            }}>
            <CardHeader
              sx={{pb: '8px'}}
              title={solarScoreCardTitle}
              subheader={solarScoreCardSubTitle}
            />
            {user?.userAddress === null || user?.userAddress === undefined ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="300px">
                <CircularProgress
                  color="primary"
                  style={{
                    display: 'table',
                    margin: ' 0 auto',
                    'vertical-align': 'middle',
                  }}
                />
              </Box>
            ) : (
              <CardContent sx={{pt: '8px'}}>
                <SearchWidget
                  onSelect={onSearchResultSelect}
                  onCancel={onCancel}
                  setOnCancel={setOnCancel}
                  address={userAddress}
                />
                {userAddress && showPrimaryAddress && (
                  <Box mt="5%">
                    <Typography
                      variant="BodySmallBold"
                      color={theme.palette.text.darkNavyBlue}>
                      Your Primary Address:
                    </Typography>
                    <Typography
                      variant="BodySmall"
                      color={theme.palette.text.darkNavyBlue}>
                      {userAddress}
                    </Typography>
                  </Box>
                )}
                <Box mt="5%">
                  {solarScore ? (
                    <SolarScoreCard score={solarScore} />
                  ) : (
                    <NoSolarScoreCard />
                  )}
                </Box>
                <Box mt="5%">
                  <Button
                    data-id="link_content"
                    data-intent="confirmational"
                    data-scope="button"
                    data-text="looksee | home | get a solar quote button click"
                    variant="submit"
                    onClick={handleOpenDialogQuote}>
                    Get a Solar Quote
                  </Button>
                </Box>
              </CardContent>
            )}
          </Card>
        </Box>
        <Box>
          <ContinueDialog
            open={openDialogQuote}
            variation="solarQuote"
            link="https://www.looksee.co.za/solar/details?cid=APP_S3fKs"
            handleClose={handleOpenDialogQuote}
          />
          <ContinueDialog
            open={openDialogTip}
            variation="solarTip"
            link={dailyTip?.link}
            handleClose={handleOpenDialogTip}
          />
        </Box>
      </Container>
    </Box>
  );
}
